<route>
{
  "meta": {
    "permission": [
      "subdomains.add_subdomain"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            dontSave
            :title="
              step == 1
                ? `${$t('register')} ${$tc('subdomain', 1)}`
                : step == 3
                ? `${$t('register')} ${$tc('contact', 1)}`
                : `${$t('register')} ${$t('company')}`
            "
            :loading="load"
          >
            <template class="text-right justify-end" v-slot:arrowLeft>
              <v-divider class="mx-4" inset vertical></v-divider>
              <i-btn
                v-if="step === 3"
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-save"
                :title="$tc('save', 1)"
                color="text-dark"
                :loading="loading"
                @click="submit"
              >
              </i-btn>
              <i-btn
                v-if="step > 1"
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-arrow-left"
                :title="$t('back')"
                color="text-dark"
                @click="step -= 1"
              >
              </i-btn>
              <i-btn
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-arrow-right"
                :title="$t('next')"
                color="text-dark"
                v-if="step < 3"
                @click="next()"
              >
              </i-btn>
            </template>
          </i-toolbar>
          <v-card-text>
            <v-stepper style="z-index: 0" v-model="step">
              <v-stepper-header>
                <v-stepper-step step="1">
                  {{ $tc('subdomain', 1) }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">
                  {{ $t('company') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">
                  {{ $tc('contact', 2) }}
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <br />
                  <v-row class="py-5">
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="subdomain"
                        :name="$tc('subdomain', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autocomplete="off"
                          outlined
                          v-model="company.schema_name"
                          :label="$tc('subdomain', 2)"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <br />
                  <v-row v-if="createSubdomain">
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="name"
                        :name="$tc('company_name', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autocomplete="off"
                          outlined
                          v-model="company.name"
                          :label="$tc('company_name', 2)"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="address"
                        :name="$tc('address', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autocomplete="off"
                          outlined
                          v-model="company.address"
                          :label="$tc('address', 2)"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="city"
                        :name="$tc('city', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autocomplete="off"
                          outlined
                          v-model="company.city"
                          :label="$tc('city', 2)"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="state"
                        :name="$tc('state', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autocomplete="off"
                          outlined
                          v-model="company.state"
                          :label="$tc('state', 2)"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="zip_code"
                        :name="$tc('zip_code', 1)"
                        rules="required|numeric"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autocomplete="off"
                          outlined
                          v-model="company.zip_code"
                          :label="$tc('zip_code', 2)"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="country"
                        :name="$t('country')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          autocomplete="off"
                          v-model="company.country"
                          name="country"
                          :error-messages="errors[0]"
                          :label="$t('country')"
                          class="secondary--text"
                          :items="country_list"
                          item-text="name"
                          item-value="pk"
                          outlined
                        ></v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="currency"
                        :name="$t('currency')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          autocomplete="off"
                          v-model="company.currency"
                          name="currency"
                          :error-messages="errors[0]"
                          :label="$tc('currency', 1)"
                          class="secondary--text"
                          :items="currency_list()"
                          :item-text="getCurrencyName"
                          item-value="pk"
                          outlined
                        ></v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="formatDate"
                        :name="$t('format_date')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          v-model="company.format_date"
                          :items="['mm-dd-yyyy', 'dd-mm-yyyy', 'yyyy-mm-dd']"
                          :error-messages="errors[0]"
                          name="formatDate"
                          autocomplete="off"
                          :label="$t('format_date')"
                          outlined
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" sm="6">
                      <ValidationProvider
                        vid="web_url"
                        :name="$t('web_url')"
                        rules="url"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="company.web_url"
                          name="web_url"
                          outlined
                          :error-messages="errors[0]"
                          :label="$t('web_url')"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <br />
                </v-stepper-content>
                <v-stepper-content step="3">
                  <br />
                  <v-col cols="12" lg="3" md="3" sm="6">
                    <ValidationProvider
                      vid="email"
                      :name="$tc('email', 1)"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        autocomplete="off"
                        outlined
                        v-model="contact.contact"
                        :error-messages="errors[0]"
                        :label="$tc('email', 1)"
                        class="secondary--text"
                        key="email-input"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      load: false,
      loading: false,
      country_list: [],
      subDomains: {},
      createSubdomain: false,
      found: {},
      step: 1,
      company: {
        pk: '',
        schema_name: '',
        domain_url: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        country: null,
        web_url: '',
        format_date: 'mm-dd-yyyy',
        currency: null,
        primary_color: '',
        secondary_color: '',
        dark: ''
      },
      contact: {
        pk: '',
        contact: '',
        type_contact: '',
        company_info: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      currency_list: 'company/getCurrencies'
    })
  },
  watch: {
    createSubdomain: {
      handler(val) {
        if (val) {
          this.createSubdomain = val
        }
        this.next()
      }
    }
  },
  methods: {
    getCurrencyName: (val) => {
      return `${val.name} (${val.code}) `
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.country_list = country.data
    },
    async getSubDomain() {
      const subDomains = await this.$api.subDomain.list({})
      this.subDomains = subDomains.data.results
      this.found = this.subDomains.filter(
        (item) => item.schema_name == this.company.schema_name
      )
      if (this.found.length == 0 && this.company.schema_name != '') {
        this.createSubdomain = true
      } else if (this.found.length > 0 && this.step == 1) {
        this.$toast.error(
          `${this.$tc('subdomain', 1)} ${this.$tc('rejected', 1)}`
        )
      }
    },
    next() {
      this.getSubDomain()
      if (this.createSubdomain) {
        this.step += 1
      }
      if (this.step == 3) {
        this.submitCompany()
      } else {
        return
      }
    },
    async submitCompany() {
      try {
        this.load = true
        let main_url = location.hostname.split('.')
        if (main_url.length === 3) {
          main_url = `${main_url[1]}.${main_url[2]}`
        } else if (main_url.length === 2) {
          main_url = `${main_url[0]}.${main_url[1]}`
        }
        this.company.domain_url = `${this.company.schema_name}.${main_url}`
        this.company.primary_color = '#34495E'
        this.company.secondary_color = '#345D48'
        this.company.dark = true
        this.company = await this.$api.company.create({
          form: this.company
        })
      } finally {
        this.load = false
      }
    },
    async submit() {
      try {
        this.contact.type_contact = 1
        this.contact.company_info = this.company.data.pk
        await this.$api.company.contact.create({ form: this.contact })
        this.$toast.success(
          `${this.$tc('subdomain', 1)} ${this.$tc('created', 2)}`
        )
        this.$router.push({ name: 'package' })
      } finally {
        this.load = false
      }
    }
  },
  mounted() {
    this.getCountries()
  }
}
</script>
